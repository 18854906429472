var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'analysis-body': true, error: _vm.analysisError }},[(_vm.analysisError)?_c('el-empty',{staticClass:"analysis-error animate__animated animate__fadeIn",attrs:{"image":require('../../../assets/img/analysis/analysis-error-bg.png'),"image-size":400}},[_c('el-row',{staticStyle:{"margin-top":"8px","font-size":"1.8rem","color":"#f56c6c","font-weight":"bold"},attrs:{"slot":"description"},slot:"description"},[_vm._v(" 大师可能正在赶来的路上，点击 "),_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.search}},[_vm._v("归大师")]),_vm._v(" 重试一下... ")],1)],1):_vm._e(),(!_vm.isNoHistoryMode && !_vm.topHsCodeArr.length && !_vm.analysisResult.hsCode && !_vm.analysisError)?_c('div',{staticClass:"animate__animated animate__fadeIn"},[_c('el-empty',{attrs:{"image":require('../../../assets/img/empty.png'),"image-size":300,"description":_vm.emptyDesc},scopedSlots:_vm._u([(_vm.loading)?{key:"description",fn:function(){return [_c('vue-typer',{attrs:{"erase-style":"clear","text":_vm.emptyDesc,"type-delay":70}})]},proxy:true}:null],null,true)})],1):(!_vm.analysisError)?[(_vm.topHsCodeStr && !_vm.isNoHistoryMode)?_c('el-row',{staticClass:"analysis-topHsCode typer-wrap"},[_c('vue-typer',{attrs:{"text":_vm.topHsCodeStr,"repeat":0,"type-delay":10},on:{"completed":function($event){return _vm.handleVueTyperComplete('topHsCode')}}})],1):_vm._e(),(_vm.showAnalysisProgress)?_c('div',{staticClass:"analysis-loading-wrap"},[_c('el-image',{staticStyle:{"height":"200px","margin":"0 auto"},attrs:{"src":require('../../../assets/img/analysis/search-loading.gif'),"fit":"cover"}}),_c('k-progress',{attrs:{"percent":_vm.analysisPercent,"active":"","color":[
                    '#f5af19',
                    '#f12711',
                    '#9254de',
                    '#40a9ff',
                    '#5cdbd3',
                ]}}),_c('div',{staticClass:"analysis-loading-desc"},[_vm._v(" 正在为您分析最适合的归类... ")])],1):_vm._e(),(_vm.showReasonTyper && _vm.analysisResult.reason)?_c('el-row',{staticClass:"analysis-reason typer-wrap",staticStyle:{"margin-top":"8px"}},[_c('vue-typer',{attrs:{"text":_vm.analysisResult.reason,"repeat":0,"type-delay":10},on:{"completed":function($event){return _vm.handleVueTyperComplete('analysis')}}})],1):_vm._e(),(_vm.analysisComplete && _vm.analysisResult.hsCode)?_c('el-row',{staticClass:"analysis-suggestion typer-wrap",staticStyle:{"margin-top":"8px"}},[_c('el-divider'),_c('vue-typer',{attrs:{"text":_vm.classifySuggestions,"repeat":0,"type-delay":10},on:{"completed":function($event){return _vm.handleVueTyperComplete('suggestion')}}}),_c('el-divider')],1):_vm._e(),(
                _vm.suggestionComplete &&
                (_vm.analysisResult.ipreFieldBaseCodeAndNameInfo || _vm.analysisResult.epreFieldBaseCodeAndNameInfo)
            )?_c('el-row',{staticStyle:{"width":"95%","margin":"8px auto 0 auto"}},[_c('div',{staticClass:"result-group-title"},[_vm._v(" 以下是该申报要素所需的监管证件： ")]),_c('el-row',{attrs:{"gutter":8,"type":"flex"}},[_c('el-col',{class:{
                        'empty-table-col': !_vm.analysisResult.epreFieldBaseCodeAndNameInfo.length,
                    },attrs:{"span":12}},[_c('div',{staticClass:"prove-table-title"},[_vm._v("出口：")]),_c('TableBorder',[_c('el-table',{staticClass:"animate__animated animate__fadeInRight",attrs:{"data":_vm.analysisResult.epreFieldBaseCodeAndNameInfo,"border":"","columns":_vm.proveColumns}},_vm._l((_vm.proveColumns),function(item){return _c('el-table-column',{key:item.prop,attrs:{"label":item.label,"prop":item.prop}})}),1)],1)],1),_c('el-col',{class:{
                        'empty-table-col': !_vm.analysisResult.epreFieldBaseCodeAndNameInfo.length,
                    },attrs:{"span":12}},[_c('div',{staticClass:"prove-table-title"},[_vm._v("进口：")]),_c('TableBorder',[_c('el-table',{staticClass:"animate__animated animate__fadeInRight",attrs:{"data":_vm.analysisResult.ipreFieldBaseCodeAndNameInfo,"border":"","columns":_vm.proveColumns}},_vm._l((_vm.proveColumns),function(item){return _c('el-table-column',{key:item.prop,attrs:{"label":item.label,"prop":item.prop}})}),1)],1)],1)],1)],1):_vm._e(),(this.suggestionComplete && _vm.topHsCodeDetail.length)?_c('el-row',{staticStyle:{"width":"95%","margin":"8px auto 0 auto"}},[_c('div',{staticClass:"result-group-title"},[_vm._v(" 以下是相似归类的详细信息： ")]),_c('code-table',{attrs:{"list":_vm.topHsCodeDetail,"showPagination":false,"row-class-name":_vm.getRowClass}})],1):_vm._e(),(this.suggestionComplete && _vm.demoTableData.length)?_c('el-row',{staticStyle:{"width":"95%","margin":"20px auto 0 auto"}},[_c('div',{staticClass:"result-group-title"},[_vm._v(" 以下是为您挑选的最匹配的归类的申报要素示例： ")]),(this.suggestionComplete && _vm.demoTableData.length)?_c('div',[_c('TableBorder',[_c('el-table',{staticClass:"animate__animated animate__fadeInRight",attrs:{"data":_vm.demoTableData,"border":""}},_vm._l((_vm.demoTableColumns),function(item){return _c('el-table-column',{key:item.prop,attrs:{"label":item.label,"prop":item.prop}})}),1)],1)],1):_vm._e()]):_vm._e(),_c('el-row',{directives:[{name:"show",rawName:"v-show",value:(this.suggestionComplete),expression:"this.suggestionComplete"}],staticStyle:{"width":"95%","margin":"20px auto 0 auto"}},[_c('HistoryElementTable',{ref:"historyElementRef",attrs:{"detailEnum":_vm.hsCodeDetailEnum}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }