<template>
    <div :class="{ 'analysis-body': true, error: analysisError }">
        <el-empty
            v-if="analysisError"
            :image="
                require('../../../assets/img/analysis/analysis-error-bg.png')
            "
            :image-size="400"
            class="analysis-error animate__animated animate__fadeIn"
        >
            <el-row
                slot="description"
                style="
                    margin-top: 8px;
                    font-size: 1.8rem;
                    color: #f56c6c;
                    font-weight: bold;
                "
            >
                大师可能正在赶来的路上，点击
                <el-button type="text" @click="search">归大师</el-button>
                重试一下...
            </el-row>
        </el-empty>
        <div
            v-if="!isNoHistoryMode && !topHsCodeArr.length && !analysisResult.hsCode && !analysisError"
            class="animate__animated animate__fadeIn"
        >
            <el-empty
                :image="require('../../../assets/img/empty.png')"
                :image-size="300"
                :description="emptyDesc"
            >
                <template #description v-if="loading">
                    <vue-typer
                    erase-style="clear"
                    :text="emptyDesc"
                    :type-delay="70"
                    ></vue-typer>
                </template>
            </el-empty>
        </div>
        <template v-else-if="!analysisError">
            <el-row
                class="analysis-topHsCode typer-wrap"
                v-if="topHsCodeStr && !isNoHistoryMode"
            >
                <vue-typer
                    :text="topHsCodeStr"
                    :repeat="0"
                    :type-delay="10"
                    @completed="handleVueTyperComplete('topHsCode')"
                ></vue-typer>
            </el-row>
            <div class="analysis-loading-wrap" v-if="showAnalysisProgress">
                <el-image
                    :src="
                        require('../../../assets/img/analysis/search-loading.gif')
                    "
                    fit="cover"
                    style="height: 200px; margin: 0 auto"
                ></el-image>
                <k-progress
                    :percent="analysisPercent"
                    active
                    :color="[
                        '#f5af19',
                        '#f12711',
                        '#9254de',
                        '#40a9ff',
                        '#5cdbd3',
                    ]"
                ></k-progress>
                <div class="analysis-loading-desc">
                    正在为您分析最适合的归类...
                </div>
            </div>
            <el-row
                v-if="showReasonTyper && analysisResult.reason"
                class="analysis-reason typer-wrap"
                style="margin-top: 8px"
            >
                <vue-typer
                    :text="analysisResult.reason"
                    :repeat="0"
                    :type-delay="10"
                    @completed="handleVueTyperComplete('analysis')"
                ></vue-typer>
            </el-row>
            <el-row
                class="analysis-suggestion typer-wrap"
                v-if="analysisComplete && analysisResult.hsCode"
                style="margin-top: 8px"
            >
                <el-divider></el-divider>
                <vue-typer
                    :text="classifySuggestions"
                    :repeat="0"
                    :type-delay="10"
                    @completed="handleVueTyperComplete('suggestion')"
                ></vue-typer>
                <el-divider></el-divider>
            </el-row>
            <el-row
                v-if="
                    suggestionComplete &&
                    (analysisResult.ipreFieldBaseCodeAndNameInfo || analysisResult.epreFieldBaseCodeAndNameInfo)
                "
                style="width: 95%; margin: 8px auto 0 auto"
            >
                <div class="result-group-title">
                    以下是该申报要素所需的监管证件：
                </div>
                <el-row :gutter="8" type="flex">
                    <el-col
                        :span="12"
                        :class="{
                            'empty-table-col': !analysisResult.epreFieldBaseCodeAndNameInfo.length,
                        }"
                    >
                        <div class="prove-table-title">出口：</div>
                        <TableBorder>
                            <el-table
                            class="animate__animated animate__fadeInRight"
                            :data="analysisResult.epreFieldBaseCodeAndNameInfo"
                            border
                            :columns="proveColumns"
                        >
                            <el-table-column
                                v-for="item in proveColumns"
                                :key="item.prop"
                                :label="item.label"
                                :prop="item.prop"
                            ></el-table-column>
                        </el-table>
                        </TableBorder>
                       
                    </el-col>
                    <el-col
                        :span="12"
                        :class="{
                            'empty-table-col': !analysisResult.epreFieldBaseCodeAndNameInfo.length,
                        }"
                    >
                        <div class="prove-table-title">进口：</div>
                        <TableBorder>
                            <el-table
                            class="animate__animated animate__fadeInRight"
                            :data="analysisResult.ipreFieldBaseCodeAndNameInfo"
                            border
                            :columns="proveColumns"
                        >
                            <el-table-column
                                v-for="item in proveColumns"
                                :key="item.prop"
                                :label="item.label"
                                :prop="item.prop"
                            ></el-table-column>
                        </el-table>
                        </TableBorder>
                        
                    </el-col>
                </el-row>
            </el-row>
            <el-row
                v-if="this.suggestionComplete && topHsCodeDetail.length"
                style="width: 95%; margin: 8px auto 0 auto"
            >
                <div class="result-group-title">
                    以下是相似归类的详细信息：
                </div>
                <code-table
                    :list="topHsCodeDetail"
                    :showPagination="false"
                    :row-class-name="getRowClass"
                ></code-table>
            </el-row>
            <el-row
                v-if="this.suggestionComplete && demoTableData.length"
                style="width: 95%; margin: 20px auto 0 auto"
            >
                <div class="result-group-title">
                    以下是为您挑选的最匹配的归类的申报要素示例：
                </div>

                <div
                    v-if="this.suggestionComplete && demoTableData.length"
                >
                    <TableBorder>
                        <el-table
                            class="animate__animated animate__fadeInRight"
                            :data="demoTableData"
                            border
                        >
                            <el-table-column
                                v-for="item in demoTableColumns"
                                :key="item.prop"
                                :label="item.label"
                                :prop="item.prop"
                            ></el-table-column>
                        </el-table>
                    </TableBorder>
                </div>
            </el-row>
            <el-row
                v-show="this.suggestionComplete"
                style="width: 95%; margin: 20px auto 0 auto"
            >
                <HistoryElementTable
                    ref="historyElementRef"
                    :detailEnum="hsCodeDetailEnum"
                ></HistoryElementTable>
            </el-row>
        </template>
    </div>
</template>

<script>
import { VueTyper } from "vue-typer";
import KProgress from "k-progress";
import {
    classifyAnalysis,
    getHsCodeByGName,
    batchGetHscodeList,
    aloneAnalysis,
} from "@/api/analysis";
import "animate.css";
import { getRandom } from "@/utils/math";
import { apiCallback } from "@/utils";
import CodeTable from "./code-table.vue";
import HistoryElementTable from "./history-element-table.vue";
import TableBorder from "./table-border.vue";

export default {
    name: "AnalysisGNameResult",
    components: {
        VueTyper,
        KProgress,
        CodeTable,
        HistoryElementTable,
        TableBorder,
    },
    data() {
        return {
            loading:false,
            topHsCodeArr: [], // 相似归类列表
            topHsCodeDetail: [],
            showAnalysisProgress: false,
            analysisPercent: 0,
            showReasonTyper: false,
            timer: null,
            analysisComplete: false,
            suggestionComplete: false,
            analysisError: false,
            formRef: null,
            analysisResult: {},
            demoTableData: [], // 申报要素示例
            demoTableColumns: [], // 申报要素示例table的column配置
            proveColumns: [
                {
                    label: "资料类型",
                    prop: "code",
                },
                {
                    label: "资料名称",
                    prop: "name",
                },
            ],
            emptyDesc: "暂无数据...",
            searchData: {
                gName: "",
            },
            hsCodeDetailEnum: {}, // hscode及其对应详情的枚举
            isNoHistoryMode: false, // 是否为无历史申报要素模式
        };
    },
    computed: {
        topHsCodeStr() {
            const indexEnum = {
                1: "Ⅰ、",
                2: "Ⅱ、",
                3: "Ⅲ、",
                4: "Ⅳ、",
                5: "Ⅴ、",
                6: "Ⅵ、",
                7: "Ⅶ、",
                8: "Ⅷ、",
                9: "Ⅸ、",
                10: "Ⅹ、",
            };
            const codeStr = this.topHsCodeArr.reduce((pre, cur, index) => {
                return `${pre}${indexEnum[index + 1]} ${cur.codeTs} ${
                    cur.gname
                }\n`;
            }, "");
            return this.topHsCodeArr.length
                ? `为您找到以下相似的归类：\n${codeStr}`
                : "";
        },
        classifySuggestions() {
            if (this.analysisResult !== null) {
                const { hsCode, gnname } = this.analysisResult;
                return `基于以上信息，推荐您使用的归类为：\n${hsCode}  ${
                    gnname || ""
                }`;
            }
        },
        suggestionHsCode() {
            return this.analysisResult.hsCode;
        },
    },
    methods: {
        getRowClass({ row }) {
            return row.codeTs === this.suggestionHsCode ? "succ-bg" : "";
        },
        loadingChange(val) {
            this.loading = val;
            if (!val) { 
                this.emptyDesc = '暂无数据...'
            }
            this.$emit("loading-change", val);
        },
        // 调整进度条数值
        progressChange(restart = false) {
            if (!restart) {
                // 进度条进度调整，如果进度大于90，则停止调整，等待analysis接口返回
                const randomNum = this.isNoHistoryMode ? getRandom(0, 1) : getRandom(0, 4);
                const resultPercent = this.analysisPercent + randomNum;

                this.analysisPercent += randomNum;
                if (resultPercent < 90) {
                    this.timer = setTimeout(() => {
                        this.progressChange();
                    }, 100);
                }else if(resultPercent < 100){
                    setTimeout(() => {
                        this.progressChange();
                    }, 10000);
                }
            } else {
                // analysis接口获取完成才会继续进行，将进度条进度调整为100
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                const randomNum = getRandom(4, 8);
                const resultPercent = this.analysisPercent + randomNum;
                if (resultPercent < 100) {
                    this.analysisPercent += randomNum;
                    setTimeout(() => {
                        this.progressChange(true);
                    }, 100);
                } else {
                    this.analysisPercent = 100;
                    this.showReasonTyper = true;
                    this.showAnalysisProgress = false;
                }
            }
        },
        handleVueTyperComplete(type) {
            switch (type) {
                case "topHsCode":
                    // 挑选3个最高频的hsCode，文字展示完成后，再展示进度条
                    this.showAnalysisProgress = true;
                    this.progressChange();
                    break;
                case "analysis":
                    // 分析文字展示完成，展示分析后匹配到的相关数据
                    this.analysisComplete = true;
                    break;
                case "suggestion":
                    this.loadingChange(false);
                    this.suggestionComplete = true;
                    break;
                default:
                    break;
            }
        },

        // 申报要素示例数据处理
        dealDemoData({ demo, element }) {
            const valueIgnoreArr = {};
            const tableHeadTitleArr = [];
            const ignoreHeaderTitle = [
                "品牌类型",
                "出口享惠情况",
                "CTIN",
                "GTIN",
                "CAS",
                "其他",
            ];
            if (element) {
                element.split("||").forEach((item, index) => {
                    if (item && !ignoreHeaderTitle.some((i) => i === item)) {
                        tableHeadTitleArr.push(item);
                    } else {
                        valueIgnoreArr[index] = true;
                    }
                });
            }
            this.demoTableColumns = tableHeadTitleArr.map((item) => {
                return {
                    label: item,
                    prop: item,
                };
            });
            this.demoTableData = demo
                ? demo.map((item) => {
                      const obj = {};
                      const valueArr = [];
                      item.split("|").forEach((item, index) => {
                          if (!valueIgnoreArr[index]) {
                              valueArr.push(item);
                          }
                      });
                      tableHeadTitleArr.forEach((item, hIndex) => {
                          obj[item] =
                              valueArr[hIndex] === "***"
                                  ? "-"
                                  : valueArr[hIndex] || "-";
                      });
                      return obj;
                  })
                : [];
        },

        resetData() {
            this.topHsCodeArr = [];
            this.showAnalysisProgress = false;
            this.analysisPercent = 0;
            this.showReasonTyper = false;
            this.analysisComplete = false;
            this.suggestionComplete = false;
            this.analysisResult = {};
            this.demoTableData = [];
            this.demoTableColumns = [];
            this.analysisError = false;
            this.emptyDesc = "暂无数据...";
            this.hsCodeDetailEnum = {};
            this.topHsCodeDetail = [];
            this.loadingChange(false);
        },

        async loadTopAndHistoryHscodeDetail(codeArr) {
            // 因为历史申报要素列表和推荐hscode列表都需要获取详情，在此处统一获取（只调用一次批量获取接口）
            const histotyList = await this.$refs.historyElementRef.init(
                this.searchData.gName,
            );
            const totalCodeArr = codeArr.concat(histotyList);
            batchGetHscodeList({
                codeStr: totalCodeArr.join(","),
                current: 1,
                size: totalCodeArr.length,
            }).then((res) => {
                const data = apiCallback(res);
                this.topHsCodeDetail = data.records.filter((record) => {
                    const isSimilar = codeArr.some((item) => {
                        return item === record.codeTs;
                    });
                    // 过滤下相似归类的详情（因为此处包含相似归类和历史归类）
                    return isSimilar;
                });
                data.records.forEach((item) => {
                    this.hsCodeDetailEnum[item.codeTs] = item;
                });
            });
        },

        finalAnalysis(searchData,codeArr) {
            const that = this;
            let reqFunc = classifyAnalysis;
            let reqParams = {
                ...searchData,
                gName: searchData.gName.trim(),
                hsCodes: codeArr.join(","),
            }
            if(codeArr.length === 0){
                reqFunc = aloneAnalysis;
                reqParams = {
                    ...searchData,
                    gName: searchData.gName.trim(),
                }
            }
            reqFunc(reqParams)
                .then((res) => {
                    if (res.data.data !== null) {
                        that.progressChange(true);
                        that.analysisResult = res.data.data;
                        if (
                            res.data.data.element &&
                            res.data.data.demo
                        ) {
                            that.dealDemoData(
                                res.data.data,
                            );
                        }
                    } else {
                        that.showAnalysisProgress = false;
                        that.analysisPercent = 0;
                        that.analysisError = true;
                        that.loadingChange(false);
                    }
                })
                .catch(() => {
                    that.showAnalysisProgress = false;
                    that.analysisPercent = 0;
                    that.analysisError = true;
                    that.loadingChange(false);
                });
        },

        search(searchData) {
            this.clear();
            this.loadingChange(true);
            const that = this;
            this.searchData.gName = searchData.gName;
            this.emptyDesc = '正在处理中，请稍候...'
            getHsCodeByGName({
                ...searchData,
                gName: searchData.gName.trim(),
            })
                .then((res) => {
                    const reqFormData = res.config.data;
                    const reqGName = reqFormData.get("gName");
                    if (reqGName === searchData.gName.trim()) {
                        if (res.data.data && res.data.data.length > 0) {
                            that.isNoHistoryMode = false;
                            that.topHsCodeArr = res.data.data;
                            that.loadingChange(true);
                            const codeArr = res.data.data.map(
                                (item) => item.codeTs,
                            );
                            that.$nextTick(() => {
                                that.loadTopAndHistoryHscodeDetail(codeArr);
                            });
                            that.finalAnalysis(searchData,codeArr);
                        } else {
                            // 如果相似归类列表为空，则调用ai获取hsCode
                            that.isNoHistoryMode = true;
                            that.loadingChange(false);
                            that.handleVueTyperComplete('topHsCode');
                            that.progressChange();
                            that.finalAnalysis(searchData,[]);
                            // this.emptyDesc =
                            //     "暂未找到相关归类信息，建议您完善查询信息后重试...";
                            // that.$message({
                            //     message: "暂未找到相关归类信息",
                            //     type: "warning",
                            // });
                        }
                    }
                })
                .catch(() => {
                    that.loadingChange(false);
                });
        },
        clear() {
            if (this.$refs.historyElementRef) {
                this.$refs.historyElementRef.reset();
            }
            this.resetData();
            this.searchData = {
                gName: "",
            };
        },
    },
};
</script>

<style lang="stylus">
.analysis-body{
    .vue-typer .custom.char.typed {
        font-size: 16px;
        line-height: 20px;
        padding: 8px 0;
        -webkit-user-select:text;
        -moz-user-select:text;
        -ms-user-select:text;
        user-select:text;
        cursor:pointer;
    }
    .el-empty__description .vue-typer .custom {
        font-size: 14px !important;
        color: #909399;
    }
    .analysis-reason,.analysis-topHsCode {
        .vue-typer .custom.char.typed {
            font-weight:bold;
        }
    }
    .analysis-suggestion{
        .vue-typer .custom.char.typed{
            font-size: 16px;
            color: #67C23A;
            font-weight:bold;
        }
    }
    .analysis-loading-wrap{
        .k-progress{
            margin-top: 8px;
            width:600px;
        }
    }
    .analysis-error{
        .el-button--text{
            font-size: 1.8rem;
        }
    }
    .empty-table-col{
        .el-table{
            display: flex;
            flex-direction: column;
        }
        .el-table__body-wrapper{
            flex-grow:1;
        }
        .el-table__empty-block{
            min-height: 48px;
        }
        .el-table__empty-text{
            line-height: 48px;
        }
    }
}
</style>

<style lang="stylus" scoped>
.analysis-body{
    width:70%;
    min-height: 50vh;
    margin: 0 auto;
    background-color: #fff;;
    padding: 24px;
    overflow: auto;
    border-radius: 0 0 4px 4px;
    /deep/ .el-divider--horizontal{
        margin: 8px 0;
    }
    &.error{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .typer-wrap{
        width: 95%;
        margin: 0 auto;
    }
    .result-group-title{
        margin: 8px 0;
        font-size:1.4rem;
        font-weight: bold;
    }
    .prove-table-title{
        font-size:1.2rem;
        margin: 8px 0;
    }
}
.analysis-loading-wrap{
    margin-top: 16px;
    width: 95%;
    margin: 0 auto;
}
.analysis-loading-desc{
    color: #999999;
    height: 1.4rem;
    font-size: 1.2rem;
    font-weight: bold;
}
.empty-table-col{
    display: flex;
    flex-direction: column;
}
</style>
