<template>
    <div class="table-border-container">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "CodeDesc",
    data() {
        return {};
    },
}
</script>
<style scoped lang="stylus">
.table-border-container {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    /deep/ .el-table--border th.el-table__cell{
        background-color: #E3F2FD ;
        color:#295878;
        font-weight: 1000;
    }
    /deep/ .el-table__body .el-table__row {
        background-color:#BBDEFB;
        color:#295878;
        font-weight: bold;
        &:nth-child(2n){
            background-color:#90CAF9;
        }
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell{
        background: #7f7fd5;
        color:#99ffff;
    }
    /deep/ .el-table .hover-row >td.el-table__cell{
        background: #7f7fd5;
        color:#99ffff;
    }
    /deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
        border-bottom:1px solid #d3fbff;
    }
    
    /deep/ .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
        border-right:1px solid #d3fbff;
    }
    
        
    
}
</style>
