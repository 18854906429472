var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.listData.length)?_c('div',{staticClass:"history-element-wrap"},[_c('div',{staticClass:"result-group-title"},[_vm._v(" 以下是与【"+_vm._s(_vm.searchGname)+"】相似品名的申报要素示例： ")]),_c('TableBorder',[_c('el-table',{attrs:{"data":_vm.listData,"border":""}},[_vm._l((_vm.historyElementColumn),function(col){return _c('el-table-column',{key:col.prop,attrs:{"label":col.label,"prop":col.prop,"width":col.width,"fixed":col.fixed,"min-width":col.minWidth},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(col.prop === 'codeTs')?_c('div',{staticClass:"hscode-col-wrap"},[_c('span',[_vm._v(_vm._s(scope.row[col.prop]))]),_c('img',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(scope.row[col.prop]),expression:"scope.row[col.prop]",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copySucc),expression:"copySucc",arg:"success"}],attrs:{"src":require("@/assets/icon/copy.svg")}})]):(
                            col.prop === 'codeDesc' &&
                            _vm.detailEnum[scope.row.codeTs]
                        )?_c('div',[_vm._v(" "+_vm._s(_vm.detailEnum[scope.row.codeTs].gname)+" ")]):(
                            col.prop === 'expTaxBack' &&
                            _vm.detailEnum[scope.row.codeTs]
                        )?_c('div',[_vm._v(" "+_vm._s(_vm.detailEnum[scope.row.codeTs].expTaxBack ? `${ _vm.detailEnum[scope.row.codeTs] .expTaxBack }%` : "")+" ")]):_c('div',[_vm._v(_vm._s(scope.row[col.prop]))])]}}],null,true)})}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right","width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleGoDetail(scope.row)}}},[_vm._v(" 详情 ")])]}}],null,false,3811297185)})],2)],1),_c('code-detail',{ref:"codeDetailRef"})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }